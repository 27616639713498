import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import loadingReducer from "../Services/globals/loading.reducer";

const makeRootReducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    global: loadingReducer,
  });
};

export default makeRootReducer;
