import dayjs from "dayjs";
import { PostMessageRN } from "./cookie";
import "../styles/css/custom.css";
var CurrencyFormat = require("react-currency-format");

const Formatting = {
  /**
   * @param {*} dataJson is data from api need to formatting for React Select.
   */
  formatRCRegist: function (dataJson, keyValue) {
    let tempJobs = [];
    if (dataJson) {
      dataJson.forEach((v) => {
        tempJobs.push({
          label: v["keterangan"] || "-",
          value: v[keyValue],
        });
      });
    }

    return tempJobs;
  },
  formatRCRegistBrw: function (dataJson, keyValue) {
    let tempJobs = [];
    if (dataJson) {
      dataJson.forEach((v) => {
        tempJobs.push({
          label: v["nama"] || "-",
          value: v[keyValue],
        });
      });
    }

    return tempJobs;
  },
  formatRC: function (dataJson, keyValue, keyLabel) {
    let tempJobs = [];
    if (dataJson) {
      dataJson.forEach((v) => {
        tempJobs.push({
          label: v[keyLabel] || "-",
          value: v[keyValue],
        });
      });
    }

    return tempJobs;
  },
  isJson: function (str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
  // formatRCRegistPekerjaan: function (dataJson) {
  //   let tempJobs = [];
  //   dataJson.map((v, i) => {
  //     return tempJobs.push({ label: v.keterangan, value: v.idPekerjaan });;
  //   });
  //   return tempJobs;
  // },
  // formatRCRegistProvinsi: function (dataJson) {
  //   let tempJobs = [];
  //   dataJson.map((v, i) => {
  //     return tempJobs.push({ label: v.keterangan, value: v.idPropinsi });;
  //   });
  //   return tempJobs;
  // },
  // formatRCRegistKota: function (dataJson) {
  //   let tempJobs = [];
  //   dataJson.map((v, i) => {
  //     return tempJobs.push({ label: v.keterangan, value: v.idKota });;
  //   });
  //   return tempJobs;
  // },
  currency: function (value, isInputFormat = false) {
    if (!value) {
      return 0;
    }

    if (isInputFormat) {
      var angka = value.toString();
      if (value.toString().substr(0, 1) === "0") {
        angka = value.toString().substr(1);
      }
      var number_string = angka.replace(/[^,\d]/g, "").toString(),
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      if (ribuan) {
        var separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return rupiah;
      // return new Intl.NumberFormat({
      //   style: "currency",
      // }).format(value);
    }

    const sparateDecimalNumber = (value + "").split(".");
    // console.log("sparateDecimalNumber[1]", sparateDecimalNumber[1]);
    let isHaveDecimal = sparateDecimalNumber[1] || false;

    if (!isHaveDecimal) {
      var number_string = value
          .toString()
          .replace(/[^,\d]/g, "")
          .toString(),
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      if (ribuan) {
        var separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return rupiah;

      // return new Intl.NumberFormat({ style: "currency" }).format(
      //   sparateDecimalNumber[0]
      // );
    }

    return (
      <span>
        {" "}
        {
          <CurrencyFormat
            value={sparateDecimalNumber[0]}
            displayType={"text"}
            thousandSeparator={true}
          />
        }
        {"."}
        <sub> {sparateDecimalNumber[1]} </sub>{" "}
      </span>
    );
  },
  currency2: function (value) {
    if (!value) {
      return 0;
    }

    const sparateDecimalNumber = (value + "").split(".");
    // console.log("sparateDecimalNumber[1]", sparateDecimalNumber[1]);
    let isHaveDecimal = sparateDecimalNumber[1] || false;

    if (!isHaveDecimal) {
      return (
        <span>
          {" "}
          {
            <CurrencyFormat
              value={sparateDecimalNumber[0]}
              displayType={"text"}
              thousandSeparator={true}
            />
          }{" "}
        </span>
      );
    }

    return (
      <span>
        {" "}
        {
          <CurrencyFormat
            value={sparateDecimalNumber[0]}
            displayType={"text"}
            thousandSeparator={true}
          />
        }
        {"."}
        <sub> {sparateDecimalNumber[1].substring(0, 2)} </sub>{" "}
      </span>
    );
  },
  onlyNumber: function (value) {
    if (!value) {
      return 0;
    }
    const removedSparator = value.replace(/,/g, "");
    if (!RegExp(/^[0-9]*$/).test(removedSparator)) {
      return removedSparator.replace(/\D/g, "");
    }

    return removedSparator;
  },
  alfaNumberic: function (value) {
    const removedSparator = value.replace(/,/g, "");

    if (!value) {
      return "";
    }

    if (!RegExp(/^[a-zA-Z0-9_]*$/).test(removedSparator)) {
      return removedSparator.replace(/\D/g, "");
    }

    return removedSparator;
  },
  getAllMonth: function (value) {
    let month = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    const result = month.map((v, i) => {
      return { label: v, value: i + 1 };
    });

    return result;
  },
  getAllMonthRegist: function (value) {
    let month = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    const result = month.map((v, i) => {
      return { label: v, value: i > 9 ? "" + (i + 1) : "0" + (i + 1) };
    });

    return result;
  },
  getAllYears: function (value) {
    let startYear = new Date().getFullYear() - 1;
    let currentYear = new Date().getFullYear() + 1,
      years = [];
    while (startYear <= currentYear) {
      currentYear--;
      years.push({ label: currentYear, value: currentYear });
    }
    return years;
  },
  getAllYearsRegist: function (value) {
    let startYear = 1961;
    let currentYear = new Date().getFullYear() - 20,
      years = [];
    while (startYear <= currentYear) {
      currentYear--;
      years.push({ label: currentYear, value: "" + currentYear });
    }
    return years;
  },
  getAllDatesRegist: function (value) {
    let tempDate = [];
    for (let i = 1; i <= dayjs().daysInMonth(); i++) {
      tempDate.push({ label: i, value: i > 9 ? "" + i : "0" + i });
    }
    return tempDate;
  },
  getAllDates: function (value) {
    let tempDate = [];
    for (let i = 1; i <= dayjs().daysInMonth(); i++) {
      tempDate.push({ label: i, value: i });
    }
    return tempDate;
  },
  checkLoopEmptyObject: function (value) {
    if (!value) {
      return;
    }

    var arrayObject = Object.keys(value).map(function (key) {
      return value[key];
    });
    // const arrayObject = Object.values(value);
    const countAllValidated = arrayObject.filter((v) => v !== "").length;

    if (countAllValidated !== arrayObject.length) {
      return true;
    }
    return false;
  },
  checkPassword: function (value) {
    if (!value) {
      return;
    }
    // const arrayObject = Object.values(value);
    var arrayObject = Object.keys(value).map(function (key) {
      return value[key];
    });

    const countAllValidated = arrayObject.filter((v) => v.length > 5).length;
    if (countAllValidated !== arrayObject.length) {
      return true;
    }
    return false;
  },
  scorePassword: function (pass) {
    var score = 0;
    if (!pass) return score;

    // award every unique letter until 5 repetitions
    var letters = new Object();
    for (var i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    var variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };

    var variationCount = 0;
    for (var check in variations) {
      variationCount += variations[check] == true ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    return parseInt(score);
  },
  checkPasswordStrength: function (pass) {
    if (pass.length < 5) {
      return;
    }

    var score = Formatting.scorePassword(pass);

    if (score > 80)
      return <lebel className="label-green">{"Password kuat!"}</lebel>;
    if (score > 40)
      return <lebel className="label-green">{"Password sedang!"}</lebel>;
    if (score >= 10)
      return <lebel className="label-error">{"Password lemah!"}</lebel>;
    return "";
  },
  consoleWrite: function ({ value, name }) {
    const constructData = {
      type: "console",
      value,
      name: `${name}-${dayjs().format("HH-mm-SSS")}`,
    };

    PostMessageRN(constructData);
  },
  delayExecution(delay, value) {
    return new Promise((resolve) => setTimeout(resolve, delay, value));
  },
  startCountDown(timeleft) {
    var downloadTimer = setInterval(function () {
      timeleft--;
      return timeleft;
      if (timeleft <= 0) clearInterval(downloadTimer);
    }, 1000);
    return downloadTimer;
  },
};

export default Formatting;
