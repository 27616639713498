import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import {
  routesHeaderDisable,
  HeaderMenu,
  resetHistory,
} from "../Constant/Constant";
import "./HeaderPages.css";
import BackArrow from "../../styles/images/back-arrow.svg";
import AuthApi from "../../Services/auth/auth.api";
import { Offline, Online } from "react-detect-offline";
import Loading from "../../components/Loading/LoadingOffline";
import { polling } from "../../utils/constant";
import { toast } from "react-toastify";
import Loadings from "../../components/Loading/Loading";

const HeaderPages = ({ children, isLoading }) => {

  const [doubleClick, setDoubleClick] = useState(0);
  let history = useHistory();

  const handleLabelMenu = () => {
    const {
      location: { state },
    } = history;

    if (state !== undefined && state.isFAQ) {
      const { title } = state;
      return title;
    }

    if (state !== undefined && state.isEvent) {
      const { title } = state;
      return title;
    }

    const item = HeaderMenu.find((v) => v.path === history.location.pathname);
    if (!item || item === undefined) {
      return "";
    }
    return item.label;
  };

  const isSubPages = () => {
    if (history.location.pathname === "/forgot-password") {
      return <img src={BackArrow} />;
    }
    
    if (history.location.pathname) {
      const path = history.location.pathname;
      return path.split("/").length > 2 ? <img src={BackArrow} /> : "";
    }
  };

  const handleBack = () => {
    if(history.location.pathname === "/dashboard" || history.location.pathname === "/login"){
      setDoubleClick(doubleClick+1);
    }

    history.goBack();
  };
  
  const OnlineCHange = (e) => {
    if (e){
      toast.success("Kembali Online")
    }
  }

  if (
    routesHeaderDisable.findIndex((v) => v === history.location.pathname) > -1
  ) {
    return (
      <>
      <div style={{display : isLoading}} >
      <Loadings />
    </div>
    {children}
      </>
    );
  }
  return (
    <>
    <div style={{display : isLoading}} >
      <Loadings />
    </div>
    <Offline polling={polling} onChange={(e) => OnlineCHange(e)}><Loading /></Offline>
      <div className="appHeader">
        <div className="left">
          {isSubPages() !== "" && (
            <a onClick={handleBack} className="headerButton goBack">
              {isSubPages()}
            </a>
          )}
        </div>
        <div className="pageTitle" style={{marginTop:'-31px'}}>{handleLabelMenu()}</div>
      </div>
      {children}
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    isLoading: state.global.isLoading
  };
};

export default connect(mapStateToProps)(HeaderPages);