import { toast } from "react-toastify";
import Api from "../../utils/api";
import {
  deleteAllToken,
  doEraseAllReload,
  saveToken,
  getToken
} from "../../utils/cookie";
import Messages from "../../utils/message";

const AuthApi = {
  postLogin: function (data) {
    return Api()
      .postRaw(`api/sstuser/v1/users/login`, data, {isLogin:true, withoutToken:true, rawJSON:true})
      .then(({data}) => {
          saveToken(data?.access_token, data?.refresh_token);
          return data;
      })
  },
  requestOTPSandi: function (data) {
    return Api()
      .postRaw(`api/apkuser/v1/users/lupaSandi`, data, {withoutToken:true, rawJSON:true})
      .then(({data}) => {
          return data;
      })
  },
  validasiOTP: function (data) {
    return Api()
      .postRaw(`api/apkuser/v1/users/validasiOTP`, data, {withoutToken:true, rawJSON:true})
      .then(({data}) => {
          return data;
      })
  },
  postLupaSandi: function (data) {
    return Api()
      .postRaw(`api/apkuser/v1/users/postLupasSandi`, data, {withoutToken:true, rawJSON:true})
      .then(({data}) => {
          return data;
      })
  },
  postLogout: function () {
    return Api()
      .postRaw(`api/apkuser/v1/users/logout`, {refresh_token : getToken(process.env.REACT_APP_KEY_REFRESH_TOKEN)}, {rawJSON:true})
      .then(({data}) => {
          deleteAllToken()
          doEraseAllReload();
          return Promise.resolve();
      })
  }
};

export default AuthApi;
