import { toast } from "react-toastify";
import Formatting from "./formattingJson";

export const onMessageRN = () => {
  document.addEventListener("message", function (event) {
    // Formatting.consoleWrite({
    //   value: event.data,
    //   name: "clickedInputFile",
    // });
    if (!Formatting.isJson(event.data)) {
      return;
    }

    const dataJson = JSON.parse(event.data);

    if (dataJson.type === "toast") {
      toast.success(dataJson.message);
    }
    return null;
  });
};
