import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import HeaderPages from "./components/HeaderPages/HeaderPages";
import Layout from "./components/Layout/Layout";
import { AppVersionWebview } from "./utils/constant";
import { PostMessageRN } from "./utils/cookie";
import { onMessageRN } from "./utils/rn-message";
import Loading from "./components/Loading/Loading";
import { getToken } from "./utils/cookie";
import TabMenu from "./components/ButtonTab/ButtonTab";

const Login = lazy(() => import("./pages/Login/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword/ForgotPassword"));
const OtpForgot = lazy(() => import("./pages/ForgotPassword/OtpForgot"));
const ResetPassword = lazy(() => import("./pages/ForgotPassword/ResetPassword"));

const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const Profile = lazy(() => import("./pages/Profile/Profile"));
const ChangePassword = lazy(() => import("./pages/ChangePassword/ChangePassword"));
const ChangeBankAccount = lazy(() => import("./pages/ChangeBankAccount/ChangeBankAccount"));
const EventProgress = lazy(() => import("./pages/EventProgress/EventProgress"));
const EventBerakhir = lazy(() => import("./pages/EventBerakhir/EventBerakhir"));
const History = lazy(() => import("./pages/History/History"));
const Notifikasi = lazy(() => import("./pages/Notifikasi/Notifikasi"));
const Withdraw = lazy(() => import("./pages/Withdraw/Withdraw"));


onMessageRN();

export const App = (props) => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          <HeaderPages>
            <Route exact path="/"><Redirect to="/login" /></Route>
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route path="/forgot-password/otp" component={OtpForgot} />
            <Route path="/forgot-password/reset" component={ResetPassword} />

            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/dashboard/history" component={History} />
            <PrivateRoute exact path="/dashboard/notifikasi" component={Notifikasi} />
            <PrivateRoute exact path="/dashboard/withdraw" component={Withdraw} />
            <PrivateRoute exact path="/dashboard/event-progress" component={EventProgress} />
            <PrivateRoute exact path="/dashboard/event-berakhir" component={EventBerakhir} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/profile/change-password" component={ChangePassword} />
            <PrivateRoute exact path="/profile/change-bank-account" component={ChangeBankAccount} />
          </HeaderPages>
        </Switch>
      </Suspense>
    </Router>
  );
};

const PrivateRoute = ({ component, isAuthenticated, ...rest }) => {
  const isValid = getToken(process.env.REACT_APP_KEY_TOKEN) ? true : false;
  return (
    <Route
      {...rest}
      render={props =>
        isValid ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location } 
            }}
          />
        )
      }
    />
  );
};

