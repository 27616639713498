import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { createBrowserHistory } from 'history';
import { routerMiddleware } from "connected-react-router";

import rootReducer from "./reducers";

export default function configureStore(preloadedState) {
  const history = createBrowserHistory();
//   history.listen((location) => {
//     ReactGA.set({ page: location.pathname });
//     ReactGA.pageview(location.pathname);
//   });

  const routeMiddleware = routerMiddleware(history);
  const middlewares = [routeMiddleware, thunkMiddleware.withExtraArgument];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(rootReducer(history), preloadedState, applyMiddleware(thunkMiddleware));
    
  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
  }

  return store;
}
