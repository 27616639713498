import * as axios from "axios";
import qs from "querystring";
import { deleteAllToken, getToken, saveToken, doEraseAllReload } from "./cookie";
import { toast } from 'react-toastify';

axios.interceptors.response.use(
  function (response) {
      return response;
  },
  function (error) {
    const originalRequest = error.config;
    console.log("config axios", error.config)
    console.log("config axios res", error.response)

    if (error.response === undefined) {
      
      toast.error("Ups, terjadi kesalahan!");
    }
    
    if (error?.response?.status === 401 && originalRequest?.url === 'api/apkuser/v1/users/refreshToken') {
      let refresh_token = getToken(process.env.REACT_APP_KEY_REFRESH_TOKEN);
      let token = getToken(process.env.REACT_APP_KEY_TOKEN);
      let headers = {
        "Content-Type": "application/json",
        "golang-auth" : "apksales22",
        "Authorization": `Bearer ${token}`
      };
  
      const client = {
        baseURL: process.env.REACT_APP_API_ENDPOINT,
        headers: headers,
      };
      // axios.post("api/apkuser/v1/users/logout", JSON.stringify({refresh_token}), client).then((res) => {
        deleteAllToken();
        doEraseAllReload();
        toast.error("Session Expired, Silahkan Login Kembali")
        setTimeout(() => {
          window.location.reload();
        },1500);
      // });
      return Promise.reject(error);
    }

    if (error?.response?.data?.status === 401 && originalRequest?.url !== 'api/apkuser/v1/users/refreshToken') {
      
      let refresh_token = getToken(process.env.REACT_APP_KEY_REFRESH_TOKEN);
      if (refresh_token){
        let token = getToken(process.env.REACT_APP_KEY_TOKEN);
        let headers = {
          "Content-Type": "application/json",
          "golang-auth" : "apksales22",
          "Authorization": `Bearer ${token}`
        };
    
        const client = {
          baseURL: process.env.REACT_APP_API_ENDPOINT,
          headers: originalRequest?.headers,
        };

        return axios.post("api/apkuser/v1/users/refreshToken",JSON.stringify({refresh_token}), client).then((res) => {
          saveToken(res?.data?.data?.access_token, res?.data?.data?.access_token);
          error.config.headers['Authorization'] = 'Bearer ' + res?.data?.data?.access_token;
          return axios.request(error.config).then((res) => res || {});
        });
      }
    }

    if (error?.response?.data?.status === 403) {
    
      toast.error(error?.response?.data?.message, {
        bodyClassName: "capitalize",
      });
      
    }

    if (error?.response?.data?.status === 404) {
    
      toast.error(error?.response?.data?.message, {
        bodyClassName: "capitalize",
      });
    }

    if (error?.response?.data?.status === 400) {
      toast.error(error?.response?.data?.message, {
        bodyClassName: "capitalize",
      });
    }

    return Promise.reject(error);
  }
);

const Api = () => ({
  Config: ({isLogin = false, withoutToken = false, multipartForm, rawJSON }) => {
    let api_token = getToken(process.env.REACT_APP_KEY_TOKEN);
    let basicAuth = Buffer.from(`${process.env.REACT_APP_UNAME_AUTH}:${isLogin ? process.env.REACT_APP_PASSWD_AUTH : process.env.REACT_APP_PASSWD_AUTH_APK}`, 'utf8').toString('base64')

    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "golang-auth" : isLogin ? "apksalestracking22" : "apksales22",
    };

    if (withoutToken) {
      headers["Authorization"] = `Basic ${basicAuth}`;
    }else{
      headers["Authorization"] = `Bearer ${api_token}`;
    }

    if (multipartForm) {
      headers["Content-Type"] = "multipart/form-data";
    }

    if (rawJSON) {
      headers["Content-Type"] = "application/json";
    }

    const client = {
      baseURL: process.env.REACT_APP_API_ENDPOINT,
      headers: headers,
    };
    return client;
  },
  Get: (url = "", config) => {
    return axios
      .get(url, Api().Config({ withoutToken: config?.withoutToken }))
      .then((res) => res?.data || {});
  },
  Post: (url, body, config) => {
    return axios
      .post(
        url,
        qs.stringify(body),
        Api().Config({ withoutToken: config?.withoutToken})
      )
      .then((res) => res?.data || {});
  },
  PostFile: (url, body, config) => {
    return axios
      .post(url, body, Api().Config({ multipartForm: config?.multipartForm }))
      .then((res) => res?.data || {});
  },
  postRaw: (url, body, config) => {
    return axios
      .post(url, body, Api().Config({ isLogin: config?.isLogin, withoutToken: config?.withoutToken, rawJSON: config?.rawJSON }))
      .then((res) => res?.data || {});
  },
});

export default Api;
