/**
 * @constant AppVersionWebview
 * if version in webview not match with app
 * will show modal force update
 */
export const AppVersionWebview = "0.0.1";

export const PlayStoreLink =
  "https://play.google.com/store/apps/details?id=com.danainwbv.lender";

export const APIUrl = process.env.REACT_APP_API_ENDPOINT_BRW+process.env.REACT_APP_API_CONNECTION;
export const polling = {enabled: true, interval: 10000};
export const OfflineText = "Gangguan Jaringan. Mohon periksa koneksi anda!.";
