export const routesHeaderFooterDisable = [
  "/login",
  "/profile/change-password",
  "/profile/change-bank-account",
  "/dashboard/event-progress",
  "/forgot-password",
  "/dashboard/history",
  "/dashboard/notifikasi",
  "/dashboard/withdraw",
];
export const routesHeaderDisable = [
  "/dashboard",
  "/login",
  "/profile"
];

export const resetHistory = [
];

export const HeaderMenu = [
  {
    path: "/profile/change-password",
    label: "Ubah Password",
  },
  {
    path: "/profile/change-bank-account",
    label: "Ubah Akun Bank",
  },
  {
    path: "/dashboard/event-progress",
    label: ""
  },
  {
    path: "/forgot-password",
    label: ""
  },
  {
    path: "/dashboard/history",
    label: "History"
  },
  {
    path: "/dashboard/notifikasi",
    label: "Notifikasi"
  },
  {
    path: "/dashboard/withdraw",
    label: "Tarik Dana"
  },
  
];
